import React, { Component } from 'react';
import BigCard from '../../components/BigCard';
import Link from 'gatsby-link'
import firebase from 'firebase';
import fire from '../../config/shit';

import NotificationSystem from 'react-notification-system';
import CENGHome from './CENGHome';
import CSPAHome from './CSPAHome';
import CENGHeader from './CENGHeader';
import CSPAHeader from './CSPAHeader';



class CHome extends React.Component {
    constructor(props) {
    super(props)

    this.state = {

        user:  null,
        email: null,
        cont: "Validating",
        
    }

    this.authListener = this.authListener.bind(this);
    this.contListener = this.contListener.bind(this);

    }

    componentDidMount() {

        this.authListener()

    }

    contListener() {

        var email = this.state.email;
       
               var cleanemail = email.replace(/\./g, ',');
       
              
               var playersRef = firebase.database().ref("Clients/ClientTemplate/UserContent/");

                playersRef.orderByChild(`${cleanemail}`).on("child_added", data => {

                    var key = data.key
                    var value = data.val()

                    console.log(value.key);

                    var toArray = Object.entries(value)

                    console.log(toArray);

                    var llave = toArray[0];
                    var valor = toArray[1];

                   
                    var mainkey = llave[0];
                    var mainval = llave[1]
    
                    console.log(mainkey + "  "+ mainval+ "  " + cleanemail)
    
                    if( cleanemail == mainkey) {
    
                        console.log("Email are Equal " + cleanemail + " " + mainkey)
                    } else  {
    
                        console.log("Emails not Equal " + cleanemail + " " + mainkey)
                    }
    
    
                    
                    if(cleanemail == mainkey && mainval == "Spanish") {

                        this.setState({cont: "Spanish"})
                        
                        
                        this.props.updateLang("Spanish")
                      
    
                     } else if(cleanemail == mainkey && mainval == "English") {
                         
                        this.setState({cont: "English"})

                        this.props.updateLang("English")
                        
                     }
                   
                });

    }

    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            
            if (user) {
                
                localStorage.setItem('user',user.uid);

                var user = firebase.auth().currentUser;
                var name, email, photoUrl, uid, emailVerified;

                if (user != null) {
                name = user.displayName;
                email = user.email;
                
                photoUrl = user.photoURL;
                emailVerified = user.emailVerified;
                uid = user.uid; 
               
               this.setState({email: email})  


               this.contListener()


            }
            } else {
                this.setState({ user: null });
                localStorage.removeItem('user');
            }
        });        
    }

    render() {

        

        if(this.state.cont == "English") {

            return (
                <div>
                    
                    <CENGHome />
                    
                </div>
            
            
            )

        } else if( this.state.cont == "Spanish") {

            return (
                <div>
                    
                    <CSPAHome />
                    
                </div>
            
            )
 
        } else {

            return (
                <div>
                    <h1>Dutch</h1>
                </div>
            )
        }

        
    }
}

export default CHome;