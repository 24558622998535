import React, { Component } from 'react'

import BigCard from '../../components/BigCard'
import CSPAHeader from './CSPAHeader'
import Link from 'gatsby-link'
import NotificationSystem from 'react-notification-system'
import Recursos from '../spacomponents/Recursos'

class CSPAHome extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)

    this.state = {
      _notificationSystem: null,
    }
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem
  }

  handleClick() {
    //replace('/ctemplate/herramientas')
  }

  render() {
    var style = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: '10px 5px 2px 1px',
        },

        success: {
          // Applied only to the success notification item
        },
      },
    }

    return (
      <div>
        <div className="BigCardGroup">
          <Link to="/ctemplate/herramientas">
            <BigCard
              icon={require('../../Images/toolscoverspa.png')}
              title="Herramientas de Eliminación de Error"
              text="Son las mismas herramientas desarroladas durante 20 años de uso y refinamiento dentro de las industrias commercial nuclear y aerea de los Estados Unidos."
              image={require('../../Images/background_1.jpg')}
            />
          </Link>
        </div>

        <h1 className="Title">Recursos</h1>
        <Recursos />
        <NotificationSystem ref="notificationSystem" style={style} />
      </div>
    )
  }
}

export default CSPAHome
