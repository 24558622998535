import React, { Component } from "react";
import fire from "../../config/shit";
import firebase from "firebase";
import "../../pages/eng/index.css";
import NotificationSystem from "react-notification-system";
import ForgotForm from "../engcomponents/ForgotForm";

class CLogin extends React.Component {
  constructor(props) {
    super(props);

    this.logIn = this.logIn.bind(this);
    this.signUp = this.signUp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fireconfig = this.fireconfig.bind(this);
    this.PasswordNotification = this.PasswordNotification.bind(this);

    this.state = {
      _notificationSystem: null,
      email: "",
      password: "",
      forgotemail: "",
      keysLocal: [""],
      keyInput: "",
      keyExists: "",
      keyStatus: [""],
      keyContent: "",
      authenticated: false
    };
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;

    var KeyCloud = firebase.database().ref("Clients/ClientTemplate/Keys");

    KeyCloud.on("value", snap => {
      this.setState({ keysLocal: snap.val() }, () => {
        console.log(this.state.keysLocal);
      });
    });
  }

  fireconfig() {
    fire
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(u => {
        console.log("SignUp Successful");
        this.setState({ authenticated: true });
        //RTD Setup Start

        var userpush = firebase
          .database()
          .ref("Clients/ClientTemplate/Users")
          .push();

        var contentpush = firebase
          .database()
          .ref("Clients/ClientTemplate/UserContent")
          .push();

        var key = userpush.key;
        var email = this.state.email;
        var cleanemail = email.replace(/\./g, ",");

        userpush.set(
          {
            Email: this.state.email,
            Client: "ClientName",
            Auth: true,
            Content: this.state.keyContent,
            license: this.state.keyInput,
            key: key,
            [cleanemail]: this.state.keyContent
          },
          () => {
            contentpush.set({
              [cleanemail]: this.state.keyContent
            });
          }
        );
      })
      .catch(error => {
        console.log(error);
        var errormessage = String(error);
        console.log(errormessage);

        this._notificationSystem.addNotification({
          message: errormessage,
          level: "error",
          position: "tc",
          title: "Check Your Credentials"
        });
      });
  }

  PasswordNotification(event) {
    event.preventDefault();
    this._notificationSystem.addNotification({
      level: "info",
      position: "tc",
      title: "Reset Your Password",
      dismissible: "button",
      children: (
        <div>
          <ForgotForm />
        </div>
      )
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  logIn(e) {
    e.preventDefault();

    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(u => {})
      .catch(error => {
        console.log(error);

        var errormessage = String(error);
        console.log(errormessage);

        this._notificationSystem.addNotification({
          message: errormessage,
          level: "error",
          position: "tc",
          title: "Check Your Credentials"
        });
      });
  }

  signUp(e) {
    e.preventDefault();

    var keyList = this.state.keysLocal;

    var submittedKey = this.state.keyInput;

    var exists = keyList.includes(submittedKey);

    this.setState({ keyExists: exists }, () => {
      if (this.state.keyExists == true && exists == true) {
        var KeyConfig = firebase
          .database()
          .ref("Clients/ClientTemplate/KeyStatus");

        KeyConfig.orderByValue().on("value", data => {
          data.forEach(snap => {
            var x = snap.key;
            var i = snap.val();

            console.log(x);
            console.log(i);

            if (x == submittedKey && i == false) {
              fire
                .auth()
                .createUserWithEmailAndPassword(
                  this.state.email,
                  this.state.password
                )
                .then(u => {
                  KeyConfig.update({
                    [submittedKey]: true
                  });

                  console.log("SignUp Successful");

                  this.setState({ authenticated: true });
                  //RTD Setup Start

                  var userpush = firebase
                    .database()
                    .ref("Clients/ClientTemplate/Users")
                    .push();

                  var contentpush = firebase
                    .database()
                    .ref("Clients/ClientTemplate/UserContent")
                    .push();

                  var key = userpush.key;
                  var email = this.state.email;
                  var cleanemail = email.replace(/\./g, ",");

                  userpush.set(
                    {
                      Email: this.state.email,
                      Client: "ClientName",
                      Auth: true,
                      Content: this.state.keyContent,
                      license: this.state.keyInput,
                      key: key,
                      [cleanemail]: this.state.keyContent
                    },
                    () => {
                      contentpush.set({
                        [cleanemail]: this.state.keyContent
                      });
                    }
                  );
                })
                .catch(error => {
                  console.log(error);
                  var errormessage = String(error);
                  console.log(errormessage);

                  this._notificationSystem.addNotification({
                    message: errormessage,
                    level: "error",
                    position: "tc",
                    title: "Check Your Credentials"
                  });
                });

              var KeyContent = firebase
                .database()
                .ref("Clients/ClientTemplate/KeyContent");

              KeyContent.orderByValue().on("value", content => {
                content.forEach(snap => {
                  var a = snap.key;
                  var b = snap.val();

                  console.log(this.state.keyContent);

                  if (b == "English" && a == submittedKey) {
                    this.setState({ keyContent: b });

                    this.props.updateTextCB("English");
                  } else if (b == "Spanish" && a == submittedKey) {
                    this.setState({ keyContent: b });

                    this.props.updateTextCB("Spanish");
                  }

                  console.log("SHIT" + snap.val());
                });
              });
            } else if (x != submittedKey && i != false) {
              console.log(submittedKey);
              console.log("key and value unpaired");
            } else if (x != submittedKey && i == false) {
              console.log("key unpaired");
            } else if (x == submittedKey && i != false) {
              this._notificationSystem.addNotification({
                message: "Key has Already Been Used",
                level: "error",
                position: "tc",
                title: "Key Error"
              });
            }
          });
        });
      } else {
        this._notificationSystem.addNotification({
          message: "Key is Invalid",
          level: "error",
          position: "tc",
          title: "Key Error"
        });
      }
    });
  }

  render() {
    var style = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px"
        },

        success: {
          // Applied only to the success notification item
          color: "white",
          background: "red"
        }
      }
    };

    return (
      <div className="FormCContainer">
        <form className="Form">
          <div className="FormCGroup">
            <label className="FormCLabel">Email address</label>
            <input
              className="FormCInput"
              value={this.state.email}
              onChange={this.handleChange}
              type="email"
              name="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="FormCGroup">
            <label className="FormCLabel">Password</label>
            <input
              className="FormCInput"
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
              name="password"
              id="exampleInputPassword1"
              placeholder=" Enter Password"
            />
          </div>

          <div className="FormCGroup">
            <label className="FormCLabel">Key</label>
            <input
              className="FormCInput"
              value={this.state.keyInput}
              onChange={this.handleChange}
              type="password"
              name="keyInput"
              id="exampleInputkey1"
              placeholder="Sign Up Only"
            />
          </div>

          <button className="FormCButton" type="submit" onClick={this.logIn}>
            Login
          </button>
          <NotificationSystem ref="notificationSystem" style={style} />
          <button className="FormCButton" onClick={this.signUp}>
            Signup
          </button>
          <button className="FormCButton" onClick={this.PasswordNotification}>
            Forgot Password?
          </button>
        </form>
      </div>
    );
  }
}

export default CLogin;
