import React, { Component } from 'react';


import fire from  "../../config/shit"

import CHome from '../../components/ctemplatecomponents/CHome';

import CLogin from '../../components/ctemplatecomponents/CLogin';

import CHeader from '../../components/ctemplatecomponents/CHeader';



import '../eng/index.css';

import NotificationSystem from 'react-notification-system';

import Wave from '../../components/Wave';


class CIndex extends React.Component {
    constructor(props) {
    super(props)
    this.state = ({
        user: null,
        content:"Alex",
        header:"Dutch",
        text: "Initial Text",

    });
    this.authListener = this.authListener.bind(this);
    this.updateText1 = this.updateText1
    this.updateLang = this.updateLang
    }

    componentDidMount() {
        this.authListener()


    }

    updateText1 = (text) => {this.setState({ text })}

    updateLang= (header) => {this.setState({ header})}


    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            console.log(user);
            if (user) {
                this.setState({ user });
                localStorage.setItem('user',user.uid);
            } else {
                this.setState({ user: null });
                localStorage.removeItem('user');
            }
        });
    }

    render() {

        if (this.state.user != null ) {

            return (
                <div>
                
                <CHeader auth="true" lang={this.state.header}  />
                    <div className="Hero">
                        <div className="HeroGroup">
                            <div>
                                <CHome lang={this.state.text} updateLang={this.updateLang}  />
                                <Wave />
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else if (this.state.user == null) {

            return (
                <div>
                
                <CHeader auth="false" />
                    <div className="Hero">
                        <div className="HeroGroup">
                            <div>
                                <CLogin updateTextCB={this.updateText1}   />
                                <Wave />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

       

    }
}

export default CIndex;